import { default as _91_46_46_46slug_93Q8JsvN7rPnMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as addressesGd1ctCIXLNMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyWjmkEassJLMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactUQMu3rQp59Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexO9oCrazb8zMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as liststGpoSh6gJiMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91variantId_93laOicPfcHtMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue?macro=true";
import { default as indexbDqRr9INIWMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue?macro=true";
import { default as _91_46_46_46slug_9374DISGo81YMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as orders2PmwezO7y4Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingpo7aSNxGBVMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userF4jcWFVklcMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editIq86dialRnMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexRf31z16ZWRMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newE1ndyEAZKdMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editYJoUhzNppNMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as index6RVqgfUF9jMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profileIxG9Uft2CgMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountMKvMLyCtI2Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93pORrbxulAYMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryyTGZQzg0vZMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartNIhR9i9mphMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45payment6u7veQzAMJMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summarysk8l7A38KUMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutxtGEUlp9KwMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordfMDKxiCz61Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPassword17jEfgXErEMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexTpQMwiwWHbMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexojTkj0axNYMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productv5webalvj9Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as registerZqY7AfLPzgMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolvefjc3NJdep5Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmszW7IGFclEdMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as store9BbeDXK9YxMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchCn0171ErDxMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionVnzKuADxoFMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectionaRseCVsRxkMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexznSsfApf9hMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClient2ooHL7grLvMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementcmUGbhnkHSMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementHhQyFRTGXUMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionVWwpZQjXylMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderehnUCBtPMqMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionVHl3MCQV3EMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugvOrThwCVAxMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormYngy0u9HqhMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalH93z7lymteMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyVWgw3TYCghMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaqKRBKEI4rMMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeft1BhbG0jEZCMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRight4PzZJSUg3oMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationn0siscNoIpMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionxI8dzt5PDdMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesTCf0iQONzAMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionTmkikqtdCUMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePagination3ycMC9cIekMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightqPTdE8Z2UzMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexqkO4SZOTc7Meta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsdiUtu30GqbMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageoBwVHd8UzJMeta } from "/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3Page7vljaG5iHaMeta } from "/home/ecomadm/app/releases/70/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Q8JsvN7rPnMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountMKvMLyCtI2Meta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressesGd1ctCIXLNMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesGd1ctCIXLNMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companyWjmkEassJLMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyWjmkEassJLMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contactUQMu3rQp59Meta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactUQMu3rQp59Meta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: liststGpoSh6gJiMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: liststGpoSh6gJiMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "offer",
    path: "offer/:id/:variantId",
    meta: _91variantId_93laOicPfcHtMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue")
  },
  {
    name: indexbDqRr9INIWMeta?.name ?? "locale-account-offers",
    path: "offers",
    meta: indexbDqRr9INIWMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_9374DISGo81YMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: orders2PmwezO7y4Meta?.name ?? "locale-account-orders",
    path: "orders",
    meta: orders2PmwezO7y4Meta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billingpo7aSNxGBVMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingpo7aSNxGBVMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userF4jcWFVklcMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editIq86dialRnMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexRf31z16ZWRMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newE1ndyEAZKdMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editYJoUhzNppNMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: index6RVqgfUF9jMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: index6RVqgfUF9jMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profileIxG9Uft2CgMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profileIxG9Uft2CgMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categoryyTGZQzg0vZMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93pORrbxulAYMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutxtGEUlp9KwMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutxtGEUlp9KwMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cartNIhR9i9mphMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartNIhR9i9mphMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45payment6u7veQzAMJMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summarysk8l7A38KUMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordfMDKxiCz61Meta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPassword17jEfgXErEMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productv5webalvj9Meta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: registerZqY7AfLPzgMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerZqY7AfLPzgMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolvefjc3NJdep5Meta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchCn0171ErDxMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchCn0171ErDxMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cmszW7IGFclEdMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmszW7IGFclEdMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: store9BbeDXK9YxMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: store9BbeDXK9YxMeta || {},
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/70/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/70/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]